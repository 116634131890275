import { Modal, Paper } from '@material-ui/core';
import React from 'react';
import "./ConfirmModal.css";
import CancelButton from '../Inspection/CancelButton/CancelButton';
import ConfirmationButton from '../Inspection/ConfirmationButton/ConfirmationButton';
const ConfirmModal = ({ open, handleConfirm, handleCancel, title}) => {
  return (
    <>
      <Modal
        className="confirm-modal"
        open={open}
        onClose={handleCancel}
      >
        <Paper className="confirm-modal-paper">
          <div className='confirm-modal-paper-title'>
            <b>{title}</b>
          </div>
          <div className='confirm-modal-buttons'>
            <CancelButton handleCancel={handleCancel} title="Hætta við" />
            <ConfirmationButton handleSubmit={handleConfirm} title="Staðfesta" />
          </div>
        </Paper>
      </Modal>
    </>
  );
}
export default ConfirmModal;