import { SET_LOGIN_USER, REFRESH_TOKEN, SET_LOGOUT_USER } from "../types";

export const setLoginUser = (user) => ({
    type: SET_LOGIN_USER,
    payload: user
});

export const setRefreshToken = (accessToken) => ({
    type: REFRESH_TOKEN,
    payload: accessToken
});

export const setLogoutUser = () => ({
    type: SET_LOGOUT_USER,
    payload: null
});
