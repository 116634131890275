import { useState } from 'react';
import loginService from '../services/loginService';
import { useDispatch } from 'react-redux';
import { setLoginUser } from "../redux/actions/userActions";

const useGetToken = (fn) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const getToken = async (code) => {
        return await loginService.getToken(code)
            .then((user) => { 
                setError(null); 
                dispatch(setLoginUser(user));
                fn();
            })
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { error, getToken };
}
export default useGetToken;