import { useState, useEffect } from "react";
import inspectionItemService from "../services/inspectionItemService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllInspectionItems = (getAnimalsString) => {
  const [inspectionItemsList, setInspectionItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired &&
    inspectionItemService
      .getAllInspectionItems(getAnimalsString)
      .then((inspectionItems) => {
        let inspectionItemsList = []
        // let inspectionSetList = []
        inspectionItems.forEach(insp => {
          insp.inspectionSets.forEach(set => {
            set.importantItems.forEach(item => {
              item.included = true
            });
            set.nonImportantItems.forEach(item => {
              item.included = true
            });
            // inspectionSetList.push(...set.inspectionSetId);
            inspectionItemsList.push(...set.importantItems);
            inspectionItemsList.push(...set.nonImportantItems);
          })
        })
        setInspectionItemsList(inspectionItemsList);
        // setInspectionSetList(inspectionSetList);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [getAnimalsString, isTokenExpired]);
  return { inspectionItemsList, isLoading, error };
};
export default useGetAllInspectionItems;
