import { Modal, Paper } from '@material-ui/core';
import React from 'react';
import "./InspectionConfirmationModal.css";
import CancelButton from '../CancelButton/CancelButton';
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import SaveButton from '../SaveButton/SaveButton';
import useGetPreviewPdfByInspectionId from '../../../hooks/pdf/useGetPreviewPdfByInspectionId';
const InspectionConfirmationModal = ({ open, handleConfirm, handleCancel, title, id}) => {
const { pdfBlob, isLoading } = useGetPreviewPdfByInspectionId(id);
  return (
    <>
      <Modal
        className="inspection-confirmation-modal"
        open={open}
        onClose={handleCancel}
      >
        <Paper className="inspection-confirmation-modal-paper">
          { isLoading ? <LoadingScreen /> 
          : 
          <> 
            <div className='inspection-confirmation-modal-paper-title'>
              <b>{title}</b>
            </div>
            <div className='inspection-confirmation-modal-buttons'>
            <div className='inspection-confirmation-modal-buttons-upper'>
              <CancelButton handleCancel={() => handleCancel(id)} title="Hætta við" />
              <ConfirmationButton handleSubmit={() => handleConfirm(id)} title="Senda skýrslu" />
            </div>
            <div className='inspection-confirmation-modal-buttons-lower'>
              <a blob href={pdfBlob && URL.createObjectURL(pdfBlob)} target="_blank" rel="noopener noreferrer">
                <SaveButton title="Skoða skýrslu" />
              </a>
            </div>
          </div>
          </>
          }
        </Paper>
      </Modal>
    </>
  );
}
export default InspectionConfirmationModal;