/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/followups`;

const createFollowUpInspection = async (inspection) => {
  return fetch(apiEndpoint , {
    method: "POST",
    body: JSON.stringify(inspection),
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

export default { createFollowUpInspection };
