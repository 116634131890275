import { useTheme } from "@emotion/react";
import { Box, Button, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useState } from "react";
import "./InspectionDetailSubmitModalList.css";

const InspectionDetailSubmitModalList = ({ items, inspectionDetailValues, triggerItemInDeviation }) => {
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const itemsPerPage = 16;
  const maxSteps = Math.ceil(Object.keys(items).length / itemsPerPage);
  let arrayToShow = [];
  for(var i = 0; i < maxSteps; i++) {
    arrayToShow.push(items.slice(i * itemsPerPage, itemsPerPage * (i+1)))
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleItemClick = (id) => {
    const clickedItem = items.find(item => item.id === id);
    clickedItem.included = !clickedItem.included
    if(!clickedItem.included) {
      const isItemInDeviationList = inspectionDetailValues.inspectionDeviations.find(dev => dev.itemId === id);
      if(isItemInDeviationList) {
        clickedItem.included = !clickedItem.included
        triggerItemInDeviation();
      }else {
        const itemToAdd = { id: id, inspectionTypeId: clickedItem.inspectionTypeId}
        inspectionDetailValues.inspectionExludedItems.push(itemToAdd);
      }
    }else {
      const index = inspectionDetailValues.inspectionExludedItems.indexOf(id);
      inspectionDetailValues.inspectionExludedItems.splice(index, 1)
    }
    setRefresh(!refresh);
  }
  items.forEach(item => {
    inspectionDetailValues.inspectionExludedItems.forEach(exluded => {
      if(item.id === exluded.id) {
        item.included = false;
      }
    });
  });
  return (
    <Box>
      <Box className='inspection-deital-submit-modal-list-container'>
        <div className='inspection-detail-submit-modal-paper-list'>
        {
          arrayToShow[activeStep].map((item, i) => (
            <div 
              className='inspection-detail-submit-modal-paper-list-item' 
              style={{backgroundColor: item.included ? 'rgb(152, 221, 142)' : 'rgb(230, 153, 153)'}}
              key={i}
              onClick={() => handleItemClick(item.id)}
            >
              {item.isName}
            </div>
          ))
        }
        </div>
      </Box>
      <MobileStepper
        className="inspection-detail-submit-modal-mobile-stepper"
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default InspectionDetailSubmitModalList;