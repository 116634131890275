import { Modal, Paper } from "@mui/material";
import React, { useState } from "react";
import CancelButton from "../CancelButton/CancelButton";
import useGetAllInspectionTypes from "../../../hooks/useGetAllInspectionTypes";
import InspectionFollowUpAddDeviationItemModal from "../InspectionFollowUpAddDeviationItemModal/InspectionFollowUpAddDeviationItemModal";
import './InspectionFollowUpAddDeviationInspectionTypeModal.css';


const InspectionFollowUpAddDeviationInspectionTypeModal = ({ open, handleClose, handleAddItemsToFollowUp}) => {
  const [inspectionTypeIdSelected, setInspectionTypeIdSelected] = useState(null);
  const [isInspectionFollowUpAddDeviationItemModalOpen, setIsInspectionFollowUpAddDeviationItemModalOpen] = useState(false);
  const { inspectionTypes } = useGetAllInspectionTypes();
  const handleInspectionTypeSelection = (inspectionTypeId) => {
    setInspectionTypeIdSelected(inspectionTypeId)
    setIsInspectionFollowUpAddDeviationItemModalOpen(true);
  }
  const handleCloseInspectionFollowUpAddDeviationItemModal = () => {
    setIsInspectionFollowUpAddDeviationItemModalOpen(false);
  }
  return (
    <Modal
      className="inspection-followup-add-deviation-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-followup-add-deviation-modal-paper">
        <div><b>Veldu skoðunarhandbók</b></div>
        <div>
          {inspectionTypes.map(inspectionType => 
            <div className="inspection-followup-add-deviation-modal-list" onClick={() => handleInspectionTypeSelection(inspectionType.id)}>{inspectionType.isName}</div>
          )}
        </div>
        <div className="inspection-followup-add-deviation-modal-button-area">
          <CancelButton
              handleCancel={handleClose} 
              title="Loka" />
        </div>
      {inspectionTypeIdSelected && 
        <InspectionFollowUpAddDeviationItemModal
          open={isInspectionFollowUpAddDeviationItemModalOpen}
          handleClose={handleCloseInspectionFollowUpAddDeviationItemModal}
          inspectionTypeId={inspectionTypeIdSelected}
          handleAddItemsToFollowUp={handleAddItemsToFollowUp}
          handleCloseAddInspectionTypeModal={handleClose}
        />
      }
      </Paper>
    </Modal>
  );
};
export default InspectionFollowUpAddDeviationInspectionTypeModal;