/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/inspectionreports`;

const getInspectionReportPreviewById = (id) => {
  return fetch(apiEndpoint + `/inspectionpreviewpdf/${id}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/pdf",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

const getInspectionReportById = (id) => {
  return fetch(apiEndpoint + `/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/pdf', // Set the appropriate Accept header
    },
  }).catch((error) => Promise.reject(error));
};

export default { getInspectionReportById, getInspectionReportPreviewById };