import React, { useState } from "react";
import "./InspectionDeviationModal.css";
import { Box, Modal, Paper, Tab, Tabs } from "@material-ui/core";
import CancelButton from "../CancelButton/CancelButton";
import InspectionItemsList from "../InspectionItemsList/InspectionItemsList";

const InspectionDeviationModal = ({ open, handleClose, details, handleOpenInspectionItemModal, inspectionTypes, values, hanldeOpenEditInspectionItem, isOpenInspection }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [itemSuccesfullyRemoved, setItemSuccessfullyRemoved] = useState(false); 
  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  }
  let devitionArrays = [];
  let seriousDeviations = [];
  let regularDeviations = [];


  if(isOpenInspection) {
    details.forEach(detail => {
      devitionArrays.push(detail.inspectionDeviations);
    });
    seriousDeviations = devitionArrays[activeTab].filter(deviation => deviation.judgementId === 3);
    regularDeviations = devitionArrays[activeTab].filter(deviation => deviation.judgementId === 2);
  }else {
    const currentInspectionType = inspectionTypes[activeTab].id;
    seriousDeviations = details.filter(deviation => deviation.judgementId === 3).filter(deviation => deviation.inspectionTypeId === currentInspectionType);
    regularDeviations = details.filter(deviation => deviation.judgementId === 2).filter(deviation => deviation.inspectionTypeId === currentInspectionType);
  }

  const handleRemoveItem = (item) => {
    const index = values.inspectionDeviations.findIndex((ide) => ide.itemId === item.itemId); 
    if(index >= 0) {
      values.inspectionDeviations.splice(index, 1);
      setItemSuccessfullyRemoved(!itemSuccesfullyRemoved);
    }
  }
  return (
    <Modal
      className="inspection-deviation-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-deviation-modal-paper">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="basic tabs example"
          TabIndicatorProps={{style: {background: 'rgb(83, 83, 83)'}}}
        >
          {inspectionTypes.map((inspectionType) => {
            return (
              <Tab
                key={inspectionType.id}
                label={inspectionType.isName}
                className="inspection-deviation-modal-tab"
                style={{backgroundColor: 'rgb(196, 255, 206)'}}
              />
            )
          })}
        </Tabs>
      </Box>
        <div className="inspection-deviation-selected">
            {details.length > 0 ? (
              <>
                <b>Alvarleg frávik</b>
                {seriousDeviations.length > 0 ? 
                  <InspectionItemsList
                    items={seriousDeviations}
                    handleOpenInspectionItemModal={handleOpenInspectionItemModal}
                    secondaryHeader="Dýrategund"
                    secondaryValue="inspectionTypeIsName"
                    values={values}
                    insideDeviation={true}
                    hanldeOpenEditInspectionItem={hanldeOpenEditInspectionItem}
                    handleRemoveItem={handleRemoveItem}
                  />
                :
                  <i>Enginn alvarleg frávik skráð eins og er</i>
                }
                <b>Frávik</b>
                { regularDeviations.length > 0 ? 
                  <InspectionItemsList
                    items={regularDeviations}
                    handleOpenInspectionItemModal={handleOpenInspectionItemModal}
                    secondaryHeader="Dýrategund"
                    secondaryValue="inspectionTypeIsName"
                    values={values}
                    insideDeviation={true}
                    hanldeOpenEditInspectionItem={hanldeOpenEditInspectionItem}
                    handleRemoveItem={handleRemoveItem}
                  />
                :
                  <i>Enginn frávik skráð eins og er</i>
                }
              </>
            ) : (
              <>
                <b>Enginn frávik skráð eins og er ...</b>
              </>
            )}
        </div>
        <div className="inspection-deviation-button-area">
          <CancelButton 
              handleCancel={handleClose} 
              title="Loka frávikarglugga" />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionDeviationModal;