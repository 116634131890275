import React from "react";
import { Button } from "@material-ui/core";
import "./CancelButton.css";

const CancelButton = ({ handleCancel, title }) => {
    return (
        <Button
            className="cancel-button"
            onClick={handleCancel}
        >
            <b>{title}</b>
        </Button>
    );
};

export default CancelButton;