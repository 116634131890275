import { useState, useEffect } from 'react';
import pdfService from '../../services/pdfService';

const useGetPreviewPdfByInspectionId = (id) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    pdfService
      .getPreviewPdfByInspectionId(id)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }
        return response.blob();
      })
      .then((blob) => {
        setPdfBlob(blob);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return { pdfBlob, isLoading, error };
};
export default useGetPreviewPdfByInspectionId;