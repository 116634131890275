import { Modal, Paper } from '@material-ui/core';
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';
import CancelButton from '../CancelButton/CancelButton';
import React from 'react';
import "./InspectionDetailSubmitModal.css";
import InspectionDetailSubmitModalList from '../InspectionDetailSubmitModalList/InspectionDetailSubmitModalList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InspectionDetailSubmitModal = ({open, inspectionItemsList, handleCloseSubmitModal, handleSubmit, animalName, inspectionDetailValues}) => {
  const handleSubmitSubmitModal = (values) => {
    handleSubmit(values);
    handleCloseSubmitModal();
  }
  const triggerItemInDeviation = () => {
    toast.error("Ekki er hægt að taka frávik úr andlagi");
  }
  return (
    <>
      <Modal
        className="inspection-detail-submit-modal"
        open={open}
        onClose={handleCloseSubmitModal}
      >
        <Paper className="inspection-detail-submit-modal-paper">
          <div className='inspection-detail-submit-modal-paper-title'>
            Staðfesta andlag fyrir {animalName}
          </div>
          <InspectionDetailSubmitModalList items={inspectionItemsList} inspectionDetailValues={inspectionDetailValues} triggerItemInDeviation={triggerItemInDeviation}/>
          <div className='inspection-detail-submit-modal-buttons'>
            <CancelButton handleCancel={handleCloseSubmitModal} title="Hætta við" />
            <ConfirmationButton handleSubmit={() => handleSubmitSubmitModal(inspectionDetailValues)} title="Staðfesta" />
          </div>
        </Paper>
      </Modal>
      <ToastContainer autoClose={2000} />
    </>
  );
}
export default InspectionDetailSubmitModal;