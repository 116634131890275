const inspectionItemValidation = ( values ) => {
  const {
    judgementId,
  } = values;
  const errors = {};
  if(judgementId === '') {
    errors.judgementId = "Tegund fráviks þarf að vera valin"
  }
  return errors;
}

export default inspectionItemValidation;