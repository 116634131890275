import { useState } from 'react';
import inspectionService from '../services/inspectionService';
import { useSelector } from 'react-redux';
import useRefreshToken from './useRefreshToken';

const useUpdateInspection = () => {
    const [updateError, setError] = useState(null);
    const user = useSelector((state) => state.user);
    const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
    const { refreshToken } = useRefreshToken();
    if(isTokenExpired) {
      refreshToken(user);
      setIsTokenExpired(user.tokenValidTo < new Date())
    }
  
    const updateInspection = async (inspection) => {
        return await inspectionService.updateInspection(inspection)
            .then(() => {setError(null)})
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { updateError, updateInspection };
}
export default useUpdateInspection;