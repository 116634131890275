import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useGetFollowUpChecksById from '../../../hooks/useGetFollowUpChecksById';
import { Box, Step, StepButton, Stepper } from '@mui/material';
import useWindowSize from '../../../hooks/useWindowSize';
import "./InspectionFollowUpContainer.css"
import InspectionFollowUpView from '../InspectionFollowUpView/InspectionFollowUpView';
import useForm from '../../../hooks/useForm';
import inspectionValidation from '../../../validations/inspectionValidation';
import InspectionFollowUpAdditionalInfo from '../InspectionFollowUpAdditionInfo/InspectionFollowUpAdditionalInfo';
import useCreateFollowUpInspection from '../../../hooks/useCreateFollowUpInspection';
import { useSelector } from 'react-redux';
import InspectionConfirmationModal from '../InspectionConfirmationModal/InspectionConfirmationModal';
const steps = ['Skoðunaratriði', 'Frágangur'];
const InspectionFollowUpInit = {
  reasonId: 3,
  identity: 1,
  establishmentName: "",
  billingSentToSSN: "",
  presentName: "",
  billingHour: 0,
  billingHourExtra: 0,
  billingHourTransport: 0,
  billingHourPreperation: 0,
  isInspectionComplete: false,
  isScheduled: false,
  inspectionPerformed: "",
  comment: "",
  authorSSN: "",
  inspectionDetails: [],
  followUpChecksToShow: []
};

const InspectionFollowUpContainer = () => {
  // TODO - add followupchecks when you havent added items
  // TODO - cannot add item that is already there
  const locationProps = useLocation();
  const { farm } = locationProps.state;
  const user = useSelector((state) => state.user);
  const [inspectionFollowUpConfirmationModalIsOpen, setInspectionFollowUpConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const {followUpChecks} = useGetFollowUpChecksById(farm.identity);
  const { createFollowUpInspection } = useCreateFollowUpInspection();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const windowSize = useWindowSize();
  const isPhone = windowSize.width <= 690;  
  const inspectionFollowUpSubmitHandler = async (values) => {
    values.identity = farm.identity;
    values.billingSentToSSN = farm.companySSN;
    values.establishmentName = farm.companyName;
    values.authorSSN = user.ssn;
    values.followUpChecksToShow.forEach(fcs => {
      fcs.inspectionDeviationImages.forEach(image => {
        if(image.id) {
          const index = fcs.inspectionDeviationImages.indexOf(image.id);
          fcs.inspectionDeviationImages.splice(index, 1);
        }
      });
      let detail = values.inspectionDetails.find(id => id.inspectionTypeId === fcs.inspectionTypeId);
      if(detail) {
        if(!fcs.checked) {
          detail.inspectionDeviations.push(fcs)
        }
        detail.inspectionIncludedItems.push(fcs.itemId)
      }else {
        let object;
        if(!fcs.checked) {
            object = {
            inspectionTypeId: fcs.inspectionTypeId,
            inspectionIncludedItems: [fcs.itemId],
            inspectionDeviations: [fcs]
          }
        }else {
          object = {
            inspectionTypeId: fcs.inspectionTypeId,
            inspectionIncludedItems: [fcs.itemId],
          }
        }
        values.inspectionDetails.push(object);       
        }
    });
    const inspectionFollowUpDTO = await createFollowUpInspection(values);
    setId(inspectionFollowUpDTO.id);
    setInspectionFollowUpConfirmationModalIsOpen(true);
    resetFields();
  }
  const {values, handleChange, handleSubmit, errors, resetFields} = 
  useForm(InspectionFollowUpInit, inspectionValidation, inspectionFollowUpSubmitHandler); //validate and errors
  followUpChecks.map(followup => (
    followup.inspectionDeviations.forEach(deviation => (
      values.followUpChecksToShow.push(deviation)
    ))
  ));  
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleAddItemsToFollowUp = (items) => {
    items.forEach(item => {
      values.followUpChecksToShow.push(item)
    });
  }
  const handleConfirmationCancel = (id) => {
    values.id = id;
    setInspectionFollowUpConfirmationModalIsOpen(false);
  }
  const handleConfirmation = (id) => {
    //closeInspection(id);
    setInspectionFollowUpConfirmationModalIsOpen(false);
    resetFields();
    const path = `/company`;
    navigate(path);
  }
  return (
    <div className='inspection-followup-container'>
      <div className={isPhone ? 'inspection-followup-container-stepper-phone' : 'inspection-followup-container-stepper'}>
        <Box sx={{ width: '85%' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
      <div className='inspection-followup-container-stepper-body'>
        {activeStep === 0 && (
          <InspectionFollowUpView followUpChecksToShow={values.followUpChecksToShow} handleAddItemsToFollowUp={handleAddItemsToFollowUp}/>
        )}
       {activeStep === 1 && (
          <InspectionFollowUpAdditionalInfo inspectionValues={values} handleChange={handleChange} errors={errors} handleSubmit={handleSubmit} />
       )
       } 
      </div>
      <InspectionConfirmationModal open={inspectionFollowUpConfirmationModalIsOpen} handleCancel={handleConfirmationCancel} handleConfirm={handleConfirmation} title="Klára skráningu" id={id}/>
    </div>
  );
}

export default InspectionFollowUpContainer;