import React from "react";
import { Modal, Button } from "@material-ui/core";
import Webcam from "react-webcam";
import "./ReactWebcam.css";
import useWindowSize from "../../hooks/useWindowSize";

const ReactWebcam = ({ webcamIsOpen, handleClose, capture, webcamRef }) => {
  const windowSize = useWindowSize();
  const videoConstraints = {
    width: windowSize.width - 20,
    height: windowSize.height - 100,
    facingMode: "environment",
  };
  return (
    <Modal
      className="react-webcam-modal"
      open={webcamIsOpen}
      onClose={handleClose}
    >
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <div className="react-webcam-capture-buttons">
          <Button
            className="react-webcame-cancel-button"
            variant="contained"
            onClick={handleClose}
          >
            Loka
          </Button>
          <Button
            className="react-webcame-capture-button"
            variant="contained"
            onClick={() => capture()}
          >
            Taka
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ReactWebcam;
