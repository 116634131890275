import { useState } from 'react';
import useRefreshToken from './useRefreshToken';
import { useSelector } from 'react-redux';
import followupService from '../services/followupService';

const useCreateFollowUpInspection = () => {
    const user = useSelector((state) => state.user);
    const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
    const { refreshToken } = useRefreshToken();
    if(isTokenExpired) {
      refreshToken(user);
      setIsTokenExpired(user.tokenValidTo < new Date())
    }
    const [error, setError] = useState(null);
    const createFollowUpInspection = async (inspection) => {
        return await followupService.createFollowUpInspection(inspection)
            .then((id) => { setError(null); return id;})
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { error, createFollowUpInspection };
}
export default useCreateFollowUpInspection;