import React from "react";
import authService from '../../services/authService'
import "./LandingPage.css";

const LandingPage = () => {
  const handleLogin = () => {
    window.location.href = authService.loginIsland();
  }
  return (
    <div className="landing-page-container">
      <header className="landing-page-header">
        <h1>Eftirlitskerfi
        </h1>
        <button onClick={handleLogin} className="landing-page-login-button">Innskrá</button>
      </header>
    </div>
  );
};

export default LandingPage; 