import React from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import useGetInspectionReportsByUserSSN from "../../../hooks/useGetInspectionReportsByUserSSN";
import { useSelector } from "react-redux";
import "./InspectionReportsContainer.css";
import InspectionReportsTable from "../InspectionReportsTable/InspectionReportsTable";
import CancelButton from "../CancelButton/CancelButton";
import { useNavigate } from "react-router";

const InspectionReportsContainer = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { inspectionReports, isLoading } = useGetInspectionReportsByUserSSN(user.ssn);
  const handleCancel = () => {
    const path = "/company";
    navigate(path);
  }
  return (
    <div className="inspection-reports-container">
      <div className="inspection-reports-container-header"><b>Mínar skýrslur</b></div>
      { isLoading ? <LoadingScreen /> : (
        <InspectionReportsTable inspectionReports={inspectionReports} />
    )}
    <CancelButton handleCancel={handleCancel} title="Til baka"/>
    </div>

  );
};

export default InspectionReportsContainer;