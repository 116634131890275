import React from "react";
import "./InspectionItemListModal.css";
import { Modal, Paper } from "@material-ui/core";
import CancelButton from "../CancelButton/CancelButton";

const InspectionItemListModal = ({ open, handleClose, items }) => {
  const distinctRegulations = [...new Set(items.map(item => item.regulationIsName))];
  const itemsByRegulations = distinctRegulations.map(regulation => {
    return items.filter(item => item.regulationIsName === regulation)
  })
  return (
    <Modal
      className="inspection-item-list-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-item-list-modal-paper">
        <div>
          <b className="inspection-item-list-modal-title">Skoðunarhandbók</b>
          {distinctRegulations.map((regulation, i) => 
            (
              <div key={i}>
                <b className="inspection-item-list-modal-header">{regulation}</b>
                {itemsByRegulations[i].map(item => (
                  <div className="inspection-item-list-item" key={item.id}>{`${item.chapterNumber}.${item.subChapterNumber}.${item.number}`} {item.isName}</div>
                ))}
                </div>
            )
          )} 
        </div>
        <div className="inspection-item-list-button-area">
          <CancelButton
            title="Loka"
            handleCancel={handleClose}
          />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionItemListModal;