import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import InspectionReportsTableItem from "../InspectionReportsTableItem/InspectionReportsTableItem";

const InspectionReportsTable = ({ inspectionReports }) => {
    return (
      <Table className="inspection-item-table-container" size="small">
      <TableHead>
        <TableRow>
          <TableCell><b>Búsnúmer</b></TableCell>
          <TableCell><b>Heiti</b></TableCell>
          <TableCell><b>Umdæmi</b></TableCell>
          <TableCell><b>Dagsetning</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {inspectionReports.length > 0 ? 
        inspectionReports.map((report, i) => (
          <InspectionReportsTableItem report={report} key={i}/>
        )
      ) : (
        <TableRow>
          <TableCell className="inspection-item-table-no-items">
            Engar skýrslur fundust fyrir þennan notanda ...
          </TableCell>
        </TableRow>
      )}
      </TableBody>
      </Table>
    );
};

export default InspectionReportsTable;