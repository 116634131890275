import React  from 'react';
import { useNavigate } from "react-router-dom";
import useGetToken from '../../hooks/useGetToken';
import useFakeLogin from '../../hooks/useFakeLogin';

const SignInOidc = () =>  {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const fn = () => {
    const path = "/company";
    navigate(path);
  }
  useFakeLogin("0203873199");
  const { getToken, error } = useGetToken(fn);
  if(error === null) {
    getToken(code);
  }
    return (
      <>
        <div>
          <p>Completing authentication</p>
        </div>
        
      </>
    );
}
export default SignInOidc;