import { useState, useEffect } from "react";
import inspectionService from "../services/inspectionService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useDeleteOpenInspection = () => {
  const [id, setId] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired && id && !isProcessing &&
      inspectionService
      .deleteOpenInspection(id)
      .then(() => {
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setId(null);
        setProcessing(false);
      });
    }

  , [id, isProcessing, isTokenExpired]);

  const handleDelete = async (id) => {
    if (!isProcessing) {
      setId(id);
    }
  }
  return { error, handleDelete };
};
export default useDeleteOpenInspection;