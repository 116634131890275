import React from "react"
import { IconButton, TextField } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


import "./InspectionNumberInput.css"
const InspectionNumberInput = ({handleChange, errors, inspectionValues, helperText, name, label}) => {
  const handleNumberInput = (e) => {
    var regex = /[0-9]|\./;
    if(regex.test(e.target.value[e.target.value.length - 1]) || e.target.value === "") {
      handleChange(e)
    }
  }
  const handleNumberIncrement = () => {
    let e = {
      target : {
        name: name,
        value: inspectionValues[name]
      }
    }
    let input = parseFloat(e.target.value);
    input += 1;
    const remainder = input % Math.floor(input);
    input -= 1;
    const isAcceptedNumber = remainder === 0.25 || remainder === 0.5 || remainder === 0.75 || remainder === 0.0;
    let number = Math.floor(e.target.value);
    if(remainder > 0 && remainder <= 0.25) {
      number += 0.25;
    } else if(remainder > 0.25 && remainder <= 0.5) {
      number += 0.50;
    } else if(remainder > 0.5 && remainder <= 0.75) {
      number += 0.75;
    } else if(remainder > 0.75 && remainder < 1){
      number += 1;
    } else {
      number += 0;
    }
    if(isAcceptedNumber) {
      number += 0.25
    }
    e.target.value = number;
    handleChange(e);
  }

  const handleNumberDecrement = () => {
    let e = {
      target : {
        name: name,
        value: inspectionValues[name]
      }
    }
    let input = parseFloat(e.target.value);
    input += 1;
    const remainder = input % Math.floor(input);
    input -= 1;    const isAcceptedNumber = remainder === 0.25 || remainder === 0.5 || remainder === 0.75 || remainder === 0;
    let number = Math.floor(e.target.value);
    if(remainder > 0 && remainder <= 0.25) {
      number += 0.25;
    } else if(remainder > 0.25 && remainder <= 0.5) {
      number += 0.50;
    } else if(remainder > 0.5 && remainder <= 0.75) {
      number += 0.75;
    } else if(remainder > 0.75 && remainder < 1){
      number += 1;
    } else {
      number += 0;
    }
    if(isAcceptedNumber) {
      number -= 0.25
    }
    if(number >= 0) {
      e.target.value = number;
      handleChange(e);
    }
  }
  return(
    <div className="inspection-number-input-container">
      <TextField 
        label={errors[name] ? errors[name] : label } 
        onChange={handleNumberInput}
        value={inspectionValues[name]}
        error={errors[name] !== undefined}
        name={name}
        helperText={helperText}
      />
      <div className="inspection-number-input-buttons">
        <IconButton onClick={handleNumberIncrement} aria-label="plus">
          <AddIcon />
        </IconButton>        
        <IconButton onClick={handleNumberDecrement} aria-label="minus">
          <RemoveIcon />
        </IconButton>          
      </div>
    </div>
  )
}

export default InspectionNumberInput;
