import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextareaAutosize } from "@material-ui/core";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import useGetAllJudgements from "../../../hooks/getAllJudgements";
import useForm from "../../../hooks/useForm";
import inspectionItemValidation from "../../../validations/inspectionItemValidation"
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import CancelButton from "../CancelButton/CancelButton";
import InspectionDeviationImage from "../InspectionDeviationImage/InspectionDeviationImage";


const InspectionFollowUpDeviationModal = ({ open, handleClose, item, followUpChecksToShow}) => {
  const { judgements } = useGetAllJudgements();
  const [webcamIsOpen, setWebcamIsOpen] = useState(false);

  const inspectionItemSubmitHandler = ( values ) => {
    let followupDeviationIndex = followUpChecksToShow.findIndex(f => f.itemId.toString() === values.itemId.toString());
    const judgement = judgements.find(j => j.id.toString() === values.judgementId.toString());
    followUpChecksToShow[followupDeviationIndex].comment = values.comment;
    followUpChecksToShow[followupDeviationIndex].judgementId = values.judgementId;
    followUpChecksToShow[followupDeviationIndex].judgementIsName = judgement.isName;
    followUpChecksToShow[followupDeviationIndex].deadlineToResolve = values.deadlineToResolve;
    handleClose();
    resetFields();
  }
  const {values, handleSubmit, handleChange, errors, resetFields} = useForm(item, inspectionItemValidation, inspectionItemSubmitHandler); //validate and errors
  const helperTextColor = values.comment.length < 300 ? "green" : "red";
  return (
    <Modal
      className="inspection-item-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-item-modal-paper">
        <div className="inspection-item-modal-paper-title">
          {item.itemName}
        </div>
        <div className="inspection-item-modal-radio-buttons">
        {errors.judgementId && (<div style={{color: 'red'}}>{errors.judgementId}</div>)}
        <FormControl variant="filled" fullWidth> 
          <InputLabel>Veldu aðgerð</InputLabel>
            <Select
              label="Veldu aðgerð"
              name="judgementId"
              value={values.judgementId.toString()}
              onChange={handleChange}
            >          
            {
              judgements.map((judgement) => (
                <MenuItem 
                  key={judgement.id} 
                  value={judgement.id.toString()}
                >
                  {judgement.isName}
                </MenuItem>
              ))
            } 
          </Select>
        </FormControl>
        </div>
        <div>
          <CalendarSelection 
            values={values} 
            valueName="deadlineToResolve" 
            handleChange={handleChange} 
            label="Frestur til" 
            disabled={false}
            errors={errors}
          />
        </div>
        <div className="inspection-item-modal-paper-text">
          <div>Skýring</div>
          <TextareaAutosize
            aria-label="empty textarea"
            value={values.comment}
            onChange={handleChange}
            name="comment"
            placeholder="Skrá skýringu hér ..."
            maxLength={350}
            style={{
              minWidth: 350,
              minHeight: 100,
              maxWidth: 350,
              maxHeight: 300,
            }}
          />
          <div style={{color: helperTextColor}}>{values.comment.length} / 350</div>
        </div>
        <div className="inspection-item-detail-image">
          <InspectionDeviationImage 
            inspectionDetailValues={values} 
            webcamIsOpen={webcamIsOpen} 
            setWebcamIsOpen={setWebcamIsOpen} 
            incomingInspection={true}
          />
        </div>
        <div className="inspection-item-button-area">
          <CancelButton 
              handleCancel={handleClose} 
              title="Hætta við frávik" />
          <ConfirmationButton 
              handleSubmit={ handleSubmit } 
              title="Staðfesta frávik" />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionFollowUpDeviationModal;