import React from "react";
import "./LoadingScreen.css"

const LoadingScreen = () => {
  return (
    <div className="company-loading">
      <div className="company-background">
        <div className="company-image"></div>
      </div>
  </div>
  );
};

export default LoadingScreen; 