import { useState, useEffect } from "react";

const useSearchBar = (list, initKey) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const searchKey = initKey || "name";
  const handleSearchBarChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    if(list.length > 0) {
    const result = list.filter((listItem) =>
      listItem[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResult(result);
    } else {
      setSearchResult(list)
    }
  }, [searchTerm, list, searchKey]);

  return { searchResult, handleSearchBarChange, searchTerm };
};

export default useSearchBar;
