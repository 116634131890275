import { useState, useEffect } from "react";
import infoService from "../services/infoService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllDistricts = () => {
  const [districts, setDistricts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired &&
    infoService
      .getAllDistricts()
      .then((districts) => {
        districts.forEach(district => {
          if(user.district === district.id)
          {
            district.filterCheck = true;
          }
        });        
        setDistricts(districts);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, isTokenExpired]);
  return { districts, isLoading, error };
};
export default useGetAllDistricts;