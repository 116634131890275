import { TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import inspectionReportService from "../../../services/inspectionReportService";
import { formatDate } from "../../helpers/formatDateHelper";

const InspectionReportsTableItem = ({ report }) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [id, setId] = useState(null);
  const myRefname= useRef();
  const handleOpenReport = (id) => {
    setId(id);
  } 
  useEffect(() => {
    if(id) {
      inspectionReportService
      .getInspectionReportById(id)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }
        return response.blob();
      })
      .then((blob) => {
        setPdfBlob(blob);
        setTimeout(() => {
          myRefname.current.click();
        }, "500");
      })
      .catch((error) => console.log(error))
      .finally(() => {
      });
    }
  }, [id]);
    return (
      <TableRow onClick={() => handleOpenReport(report.inspectionId)}>
        <TableCell>{report.establishmentName}</TableCell>
        <TableCell>{report.identity}</TableCell>
        <TableCell>{report.districtShortName}</TableCell>
        <TableCell>{formatDate(report.inspectionPerformed)}</TableCell>
        <TableCell>
          <a ref={myRefname} blob href={pdfBlob && URL.createObjectURL(pdfBlob)} target="_blank" rel="noopener noreferrer">
          </a>
        </TableCell>
      </TableRow>
    );
};

export default InspectionReportsTableItem;