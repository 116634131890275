/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/inspections`;

const createInspection = async (inspection) => {
  return fetch(apiEndpoint , {
    method: "POST",
    body: JSON.stringify(inspection),
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};

const updateInspection = async (inspection) => {
  return fetch(apiEndpoint + `/${inspection.id}` , {
    method: "PUT",
    body: JSON.stringify(inspection),
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};
const getAllOpenInspectionsByUser = (kt) => {
  return fetch(apiEndpoint + `/users/${kt}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
};
 
const deleteOpenInspection = (id) => {
  return fetch(apiEndpoint + `/${id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
}

const closeInspection = async (id) => {
  return fetch(apiEndpoint + `/${id}/close`, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(handleData)
    .catch((error) => Promise.reject(error));
}

export default { createInspection, updateInspection, getAllOpenInspectionsByUser, deleteOpenInspection, closeInspection };
