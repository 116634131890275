import React from "react";
import InspectionItemsListItem from "../InspectionItemsListItem/InspectionItemsListItem";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import "./InspectionItemsList.css";
import useWindowSize from "../../../hooks/useWindowSize";
import InspectionItemsListItemEdit from "../InspectionItemsListItemEdit/InspectionItemsListItemEdit";

const InspectionItemsList = ({ items, handleOpenInspectionItemModal, secondaryHeader, secondaryValue, values, insideDeviation, handleRemoveItem, previousDeviations }) => {
  const windowSize = useWindowSize();
  const isPhone = windowSize.width <= 690;
  const itemsToShow = isPhone ? items.slice(0, 6) : items.slice(0,16);
  return (
      <Table className="inspection-item-table-container" size="small">
        <TableHead>
          <TableRow>
            <TableCell><b>Skoðunaratriði</b></TableCell>
            <TableCell align="right"><b>{secondaryHeader}</b></TableCell>
            {insideDeviation && <TableCell align="right"><b>Aðgerð</b></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {insideDeviation ?
          items.length > 0 && (
            itemsToShow.map((item, i) => (
              <InspectionItemsListItemEdit
                item={item}
                handleRemoveItem={handleRemoveItem}
                key={i}
                secondaryValue={secondaryValue}
                values={values}
              />
            )))
        : items.length > 0 ? (
            itemsToShow.map((item, i) => (
              <InspectionItemsListItem
                item={item}
                handleOpenInspectionItemModal={handleOpenInspectionItemModal}
                key={i}
                secondaryValue={secondaryValue}
                values={values}
                previousDeviations={previousDeviations}
              />
            ))
          ) : (
            <TableRow>
              <TableCell className="inspection-item-table-no-items">
                Ekki tókst að sækja skoðunar atriði ...
              </TableCell>
            </TableRow>
        )}
        </TableBody>
      </Table>
  );
};

export default InspectionItemsList;
