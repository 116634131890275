
import React, { useRef, useState } from "react";
import { Fab } from "@material-ui/core";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import ReactWebcam from "../../ReactWebcam/ReactWebcam";
import "./InspectionDeviationImage.css";

const InspectionDeviationImage = ({ inspectionDetailValues, webcamIsOpen, setWebcamIsOpen, incomingInspection}) => {
  const [refresh, setRefresh] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        inspectionDetailValues.inspectionDeviationImages.push(reader.result);
        setRefresh(!refresh)
      };
      reader.readAsDataURL(file);
    }
  };
  const dosmth = (e) => {
    inspectionDetailValues.inspectionDeviationImages.splice(e.target.id, 1);
    setRefresh(!refresh)
  }
  const webcamRef = useRef(null);
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if(imageSrc !== null) {
      inspectionDetailValues.inspectionDeviationImages.push(imageSrc);
    }
    setWebcamIsOpen(false);
  };
  const saveImage = (image) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = 'captured_image.jpg';
    a.click();
  }
  const handleClose = () => {
    setWebcamIsOpen(false);
  }
  const handleImageString = (image) => {
    if(typeof(image) === "string") {
      return image;
    }else {
      return `data:image/jpg;base64,${image.imageBase64}`;
    }
  }
  return (
    <>
      <div className="inspection-deviation-image-container">
        <div className="inspection-deviation-image-select-photo">
          <div className="inspection-deviation-image-title">Velja mynd</div>
          <div>
            <input
              accept="image/*"
              id="add-file-button"
              multiple
              type="file"
              hidden
              onChange={handleImageUpload} 
              />
            <label htmlFor="add-file-button">
              <Fab component="span">
                <AddPhotoAlternateIcon />
              </Fab>
            </label>
          </div>
        </div>
        <div className="inspection-deviation-image-take-photo">
          <div className="inspection-deviation-image-title">Taka mynd</div>
          <div>
            <button
              id="take-photo-button"
              hidden
              onClick={() => setWebcamIsOpen(true)}
            />
            <label htmlFor="take-photo-button">
              <Fab component="span">
                <PhotoCamera />
              </Fab>
            </label>
          </div>
        </div>
      </div>

      <div className="inspection-item-photos-container">
        {inspectionDetailValues.inspectionDeviationImages.map((image, i) => (
          <div className="inspection-item-photos-taken" key={i}>
            <img src={handleImageString(image)} onClick={() => saveImage(image)} alt="Red dot" />
            <button id={i} onClick={dosmth} style={{backgroundColor: "#f94449"}}>delete</button>
          </div>
        ))}
      </div>
      <ReactWebcam
        webcamIsOpen={webcamIsOpen}
        capture={capture}
        webcamRef={webcamRef}
        handleClose={handleClose}
      />
    </>
  );
};
export default InspectionDeviationImage;