import React, { useState } from "react";
import "./AddInspectionTypeModal.css";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import { Checkbox, FormControlLabel, FormGroup, Modal, Paper } from "@mui/material";

const AddInspectionTypeModal = ({ open, handleClose, inspectionTypes, handleSubmitInspectionTypes, farm}) => {
  const [refresh, setRefresh] = useState(false);
  const handleAddInspectionType = (inspectionType) => {
    var idAlreadySelectedIndex = farm.inspectionTypeIds.findIndex(iti => iti === inspectionType.id);
    if(idAlreadySelectedIndex > -1) {
      inspectionType.isOnFarm = false;
      farm.inspectionTypeIds.splice(idAlreadySelectedIndex, 1);
    }else {
      inspectionType.isOnFarm = true;
      farm.inspectionTypeIds.push(inspectionType.id);
    }
    setRefresh(!refresh);
  }
  return (
    <Modal
      className="add-inspection-type-modal-container"
      open={open}
      onClose={handleClose}
    >
      <Paper className="add-inspection-type-modal-paper">
        <div>Veldu dýrategundir</div>
        <div >
          <FormGroup className="add-inspection-type-modal-body">
            {
              inspectionTypes.map((inspectionType) => (
                <FormControlLabel 
                  control={
                    <Checkbox onClick={() => handleAddInspectionType(inspectionType)}/>}
                  name={inspectionType.isName}
                  label={inspectionType.isName} 
                  key={inspectionType.id} 
                  value={inspectionType.id}
                  checked={inspectionType.isOnFarm}
                />
              ))
            }      
          </FormGroup>
        </div>
        <div className="add-inspection-type-modal-button-area">
          <ConfirmationButton 
              handleSubmit={ handleSubmitInspectionTypes } 
              title="Uppfæra" />
        </div>
      </Paper>
    </Modal>
  );
};
export default AddInspectionTypeModal;