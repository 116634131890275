import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './CompanyDetailsCard.css';

 const CompanyDetailsCard = ({ fpt }) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography>
          {fpt.farmProductionTypeName}
        </Typography>
        <Typography>
          Fjöldi: {fpt.liveAnimals}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Eftirlitsskýrsla</Button>
      </CardActions>
    </Card>
  );
}
export default CompanyDetailsCard;