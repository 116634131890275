import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import "./InspectionItemsListItem.css";

const InspectionItemsListItem = ({ item, handleOpenInspectionItemModal, secondaryValue, values, previousDeviations }) => {
  const itemIsSelected = values.inspectionDeviations.find(i => i.itemId === item.id);
  const previousDeviation = previousDeviations.find(pd => pd.itemId === item.id);
  return (
    <TableRow className="inspection-items-list-item" style={itemIsSelected && {backgroundColor: 'rgb(255, 217, 217)'}} onClick={() => handleOpenInspectionItemModal(item)}>
        <TableCell>{item.isName}{previousDeviation && <FlagIcon style={{marginLeft: "5px", marginTop: "-5px", color: "orange"}}/>}</TableCell>
        <TableCell align="right">{item[secondaryValue]}</TableCell>
    </TableRow>
  );
};

export default InspectionItemsListItem;
