import { useState, useEffect } from "react";
import loginService from "../services/loginService";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../redux/actions/userActions";

const useFakeLogin = (kt) => {
    const [error, setError] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isProcessing) {
            setProcessing(true);
            loginService
                .fakeUser(kt)
                .then((data) => {
                    data.loggedIn = true;
                    data.loggedInTimeStamp = Date.now();
                    dispatch(setLoginUser(data));
                    setError(null);
                })
                .catch((error) => setError(error.message))
                .finally(() => {
                    setProcessing(false);
                });
        }
    }, [isProcessing, kt, dispatch]);
    
    const handleLogIn = () => {
        setLoggedIn(true);
    }
    return { error, isProcessing, loggedIn, handleLogIn };
};
export default useFakeLogin;