/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import loginService from "./loginService";

var apiEndpoint

function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}

loginService.getCodeChallenge().then((challenge) => {
  apiEndpoint = process.env.REACT_APP_ENVIRONMENT === 'staging' ?
  'https://identity-server.staging01.devland.is/connect/authorize?response_type=code&client_id=%40mast.is%2Feftirlitskerfi&scope=openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Feftirlit-dev.mast.is/signinoidc&code_challenge='
  : 
  'https://innskra.island.is/connect/authorize?response_type=code&client_id=%40mast.is%2Feftirlitskerfi&scope=openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Feftirlit.mast.is/signinoidc&code_challenge='
  apiEndpoint += challenge;
  apiEndpoint += "&code_challenge_method=S256";});
  
  const loginIsland = () => {
    sleep(5000000)
  return apiEndpoint;
};

export default { loginIsland };