import React from "react";
import { Button } from "@material-ui/core";
import "./SaveButton.css";

const SaveButton = ({ handleSave, title }) => {
    return (
        <Button
            className="save-button"
            onClick={handleSave}
        >
            <b>{title}</b>
        </Button>
    );
};

export default SaveButton;