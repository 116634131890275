import React, { useState } from "react";
import "./InspectionDetailView.css";
import useGetAllInspectionItems from "../../../hooks/getAllInspectionItems";
import InspectionItemModal from "../InspectionItemModal/InspectionItemModal";
import useSearchBar from "../../../hooks/useSearchBar";
import SearchBar from "../../SearchBar/SearchBar";
import InspectionItemsList from "../InspectionItemsList/InspectionItemsList";
import InspectionDetailSubmitModal from "../InspectionDetailSubmitModal/InspectionDetailSubmitModal";
import useForm from "../../../hooks/useForm";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import CancelButton from "../CancelButton/CancelButton";
import InspectionDetailCancelSubmitModal from "../InspectionDetailCancelSubmitModal/InspectionDetailCancelSubmitModal";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { IconButton } from "@mui/material";
import InspectionDeviationModal from "../InspectionDeviationModal/InspectionDeviationModal";
import InspectionItemListModal from "../InspectionItemListModal/InspectionItemListModal";
import { useNavigate } from "react-router";

/**
 * @author [Árni Magnússon](https://github.com/arnimegg)
 * @category Inspection
 * @component holds all the components for user to create a inspection
 */
const InspectionDetailInit = {
  inspectionTypeId: "",
  inspectionDeviations: [],
  checked: false,
  inspectionExludedItems: []
};

const InspectionDetailView = ( { 
  inspectionValues, 
  inspectionType, 
  inspectionDetailSubmitModalIsOpen, 
  handleOpenSubmitModal, 
  handleCloseSubmitModal,
  cancelModalIsOpen,
  handleOpenCancelModal,
  handleCloseCancelModal,
  inspectionTypes,
  isOpenInspection,
  inspectionItemModalIsOpen,
  setInspectionItemModalIsOpen,
  callBackRef,
  isleyfurChecks
} ) => {
  const [itemSelected, setItemSelected] = useState(null);
  const [inspectionDeviationModalIsOpen, setInspectionDeviationModalIsOpen] = useState(false);
  const [inspectionItemListModalIsOpen, setInspectionItemListModalIsOpen] = useState(false);
  const { inspectionItemsList } = useGetAllInspectionItems(inspectionType.id.toString());
  let navigate = useNavigate();
  const previousDeviationsObj = isleyfurChecks.find(ic => ic.previousInspectionTypeId === inspectionType.id);
  const previousDeviations = previousDeviationsObj ? previousDeviationsObj.previousInspectionDeviations : [];
  const inspectionDetailSubmitHandler = (values) => {
    inspectionType.checked = true;
    values.checked = true;
    const reOpenedDetailIndex = inspectionValues.inspectionDetails.findIndex(detail => detail.inspectionTypeId === inspectionType.id);
    if(reOpenedDetailIndex >= 0 ) {
      inspectionValues.inspectionDetails.splice(reOpenedDetailIndex, 1);
    }
    var deviations = values.inspectionDeviations.filter(dev => dev.inspectionTypeId === inspectionType.id);
    var excluded = values.inspectionExludedItems.filter(exl => exl.inspectionTypeId === inspectionType.id).map(x => x.id);
    const submitObject = isOpenInspection ? {
      id: values.id,
      inspectionTypeId: inspectionType.id,
      inspectionDeviations: deviations,
      inspectionExludedItems: excluded
    } :
    {
      inspectionTypeId: inspectionType.id,
      inspectionDeviations: deviations,
      inspectionExludedItems: excluded
    }
    inspectionValues.inspectionDetails.push(submitObject);
  }
  const incomingInspectionDetail = isOpenInspection && 
    inspectionValues.inspectionDetails.find(idetail => idetail.inspectionTypeId === inspectionType.id);
  const {values} = useForm(isOpenInspection ? incomingInspectionDetail : InspectionDetailInit, inspectionDetailSubmitHandler);  
  
  const handleOpenInspectionItemModal = (item) => {
    const itemAlreadySelected = values.inspectionDeviations.find(i => i.itemId === item.id);
    if(!itemAlreadySelected) {
      setItemSelected(item);
      setInspectionItemModalIsOpen(true);
    }
  };
  
  const handleCloseInspectionItemModal = () => {
    setInspectionItemModalIsOpen(false);
  };

  const handleOpenInspectionDeviationModal = () => {
    setInspectionDeviationModalIsOpen(true);
  }

  const handleCloseInspectionDeviationModal = () => {
    setInspectionDeviationModalIsOpen(false);
  }

  const handleOpenInspectionItemListModal = () => {
    setInspectionItemListModalIsOpen(true);
  }

  const handleCloseInspectionItemListModal = () => {
    setInspectionItemListModalIsOpen(false);
  } 
  const handleCancelInspection = () => {
    inspectionValues = [];
    inspectionType.checked = false;
    navigate("/company");
  }
  const handleReOpenInspection = () => {
    inspectionType.checked = false;
    handleCloseCancelModal();
  }
  var currentTabDeviations = values.inspectionDeviations.map(dev => dev.inspectionTypeId === inspectionType.id);
  const { searchResult, handleSearchBarChange, searchTerm } = useSearchBar(
    inspectionItemsList,
    "isName"
  );
  callBackRef.current = () => {
    values.inspectionExludedItems.length = 0;
    values.inspectionDeviations.length = 0;
  }
  return (
    <>
      {inspectionItemsList.length > 0 && (
      <>
        <div className="inspection-detail-view-container">
          <div className="inspection-detail-view-container-upper">
            <div className="inspection-detail-deviation-view" onClick={handleOpenInspectionDeviationModal}>
              Frávik ( {currentTabDeviations.length} )
            </div>
            <SearchBar
              searchTerm={searchTerm}
              handleSearchBarChange={handleSearchBarChange}
              placeHolder="Skoðunaratriði"
            />
            <IconButton className="inspection-detail-view-book-icon" onClick={handleOpenInspectionItemListModal}>
              <ImportContactsIcon />
            </IconButton>          </div>
          { !inspectionType.checked && (
          <InspectionItemsList
            items={searchResult}
            handleOpenInspectionItemModal={handleOpenInspectionItemModal}
            secondaryHeader="Reglugerð"
            secondaryValue="regulationIsName"
            values={values}
            insideDeviation={false}
            previousDeviations={previousDeviations}
          />
          )}
        </div>
      </>
      )}
      <div className="inspection-deital-view-buttons">
        <CancelButton handleCancel={handleCancelInspection} title="Hætta skráningu" />
        {
          inspectionType.checked ?
            ( <CancelButton handleCancel={handleOpenCancelModal} title="Enduropna skráningu" />
            ) : (
            <ConfirmationButton handleSubmit={handleOpenSubmitModal} title={`Staðfesta`} />
            )
            
        }
      </div>
      {itemSelected && (
        <InspectionItemModal
          open={inspectionItemModalIsOpen}
          handleCloseInspectionItemModal={handleCloseInspectionItemModal}
          item={itemSelected}
          inspectionDetailValues={values}
          isOpenInspection={isOpenInspection}
        />
      )}
      {(
        <InspectionDetailSubmitModal
          open={inspectionDetailSubmitModalIsOpen}
          inspectionItemsList={inspectionItemsList}
          handleCloseSubmitModal={handleCloseSubmitModal}
          handleSubmit={inspectionDetailSubmitHandler}
          animalName={inspectionType.isName}
          inspectionDetailValues={values}
      />
      )}
      {(
        <InspectionDetailCancelSubmitModal 
          open={cancelModalIsOpen} 
          handleConfirm={handleReOpenInspection} 
          handleCancel={handleCloseCancelModal}
        />
      )}
      {(
        <InspectionDeviationModal 
          open={inspectionDeviationModalIsOpen}
          handleClose={handleCloseInspectionDeviationModal}
          details={isOpenInspection ? inspectionValues.inspectionDetails : values.inspectionDeviations}
          handleOpenInspectionItemModal={handleOpenInspectionItemModal}
          inspectionTypes={inspectionTypes}
          values={values}
          item={itemSelected}
          isOpenInspection={isOpenInspection}
        />
      )}
      {(
        <InspectionItemListModal
          open={inspectionItemListModalIsOpen}
          handleClose={handleCloseInspectionItemListModal}
          items={inspectionItemsList}
        />
      )}
    </>
  );
};

export default InspectionDetailView;
