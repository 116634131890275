import { Modal, Paper } from '@material-ui/core';
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';
import CancelButton from '../CancelButton/CancelButton';
import React from 'react';
import "./InspectionDetailCancelSubmitModal.css";
const InspectionDetailCancelSubmitModal = ({ open, handleConfirm, handleCancel }) => {
  return (
    <>
      <Modal
        className="inspection-detail-cancel-submit-modal"
        open={open}
        onClose={handleCancel}
      >
        <Paper className="inspection-detail-cancel-submit-modal-paper">
          <div className='inspection-detail-cancel-submit-modal-paper-title'>
            <b>Ertu viss um að þú viljir enduropna skráninguna?</b>
          </div>
          <div className='inspection-detail-cancel-submit-modal-buttons'>
            <CancelButton handleCancel={handleCancel} title="Hætta við" />
            <ConfirmationButton handleSubmit={handleConfirm} title="Staðfesta" />
          </div>
        </Paper>
      </Modal>
    </>
  );
}
export default InspectionDetailCancelSubmitModal;