import React from "react";
import { Button } from "@material-ui/core";
import "./ConfirmationButton.css";

const ConfirmationButton = ({ handleSubmit, title }) => {
    return (
        <Button
            className="confirm-button"
            onClick={handleSubmit}
        >
            <b>{title}</b>
        </Button>
    );
};

export default ConfirmationButton;
