import CancelButton from "../CancelButton/CancelButton";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import InspectionAdditionalInfoInput from "../InspectionAdditionalInfoInput/InspectionAdditionalInfoInput";

const InspectionFollowUpAdditionalInfo = ( {inspectionValues, handleChange, errors, handleSubmit, handleCancelInspection} ) => {
  return (
    <div className="inspection-additional-info-view">
      <InspectionAdditionalInfoInput inspectionValues={inspectionValues} handleChange={handleChange} errors={errors}/>
      <div className="inspection-additional-info-buttons">
        <div className="inspection-additional-info-buttons-upper">
          <CancelButton title="Hætta skráningu" handleCancel={handleCancelInspection}/>
          <ConfirmationButton
            title="Klára skráningu"
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default InspectionFollowUpAdditionalInfo;