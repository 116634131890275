import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextareaAutosize } from "@material-ui/core";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import "./InspectionItemModal.css";
import useGetAllJudgements from "../../../hooks/getAllJudgements";
import useForm from "../../../hooks/useForm";
import inspectionItemValidation from "../../../validations/inspectionItemValidation"
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import CancelButton from "../CancelButton/CancelButton";
import InspectionDeviationImage from "../InspectionDeviationImage/InspectionDeviationImage";

const InspectionDeviationInit = {
  itemId: '',
  judgementId: '',
  comment: '',
  deadlineToResolve: null,
  inspectionDeviationImages: []
};

const InspectionItemModal = ({ open, handleClose, item, inspectionDetailValues, handleCloseInspectionItemModal}) => {
  const { judgements } = useGetAllJudgements();
  const [webcamIsOpen, setWebcamIsOpen] = useState(false);
  const handleResetInspectionItem = () => {
    InspectionDeviationInit.inspectionDeviationImages = []
    InspectionDeviationInit.comment = '';
    resetFields();
    handleCloseInspectionItemModal();
  }
  const inspectionItemSubmitHandler = ( values ) => {
    values.itemId = item.id;
    values.isName = item.isName;
    values.inspectionTypeIsName = item.inspectionTypeIsName;
    values.inspectionTypeId = item.inspectionTypeId;
    values.regulationIsName = item.regulationIsName;
    values.judgementId = parseInt(values.judgementId);
    inspectionDetailValues.inspectionDeviations.push({...values})
    handleResetInspectionItem();
  }
  const handleJudgementCommentChange = (id) => {
    if(id === 2) {
      values.comment = item.deviationText ? item.deviationText : values.comment;
    } else if(id === 3) {
      values.comment = item.deviationSeriousText ? item.deviationSeriousText : values.comment;
    }
  }

  const {values, handleSubmit, handleChange, errors, resetFields} = useForm(InspectionDeviationInit, inspectionItemValidation, inspectionItemSubmitHandler); //validate and errors
  const helperTextColor = values.comment.length < 300 ? "green" : "red";
  return (
    <Modal
      className="inspection-item-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-item-modal-paper">
        <div className="inspection-item-modal-paper-title">
          {item.isName}
        </div>
        <div className="inspection-item-modal-radio-buttons">
        {errors.judgementId && (<div style={{color: 'red'}}>{errors.judgementId}</div>)}
        <FormControl variant="filled" fullWidth> 
          <InputLabel>Veldu aðgerð</InputLabel>
            <Select
              label="Veldu aðgerð"
              name="judgementId"
              value={values.judgementId.toString()}
              onChange={handleChange}
            >          
            {
              judgements.map((judgement) => (
                <MenuItem 
                  key={judgement.id} 
                  value={judgement.id.toString()}
                  onClick={() => handleJudgementCommentChange(judgement.id)}
                >
                  {judgement.isName}
                </MenuItem>
              ))
            } 
          </Select>
        </FormControl>
        </div>
        <div>
          <CalendarSelection 
            values={values} 
            valueName="deadlineToResolve" 
            handleChange={handleChange} 
            label="Frestur til" 
            disabled={false}
            errors={errors}
          />
        </div>
        <div className="inspection-item-modal-paper-text">
          <div>Skýring</div>
          <TextareaAutosize
            aria-label="empty textarea"
            value={values.comment}
            onChange={handleChange}
            name="comment"
            placeholder="Skrá skýringu hér ..."
            maxLength={350}
            style={{
              minWidth: 350,
              minHeight: 100,
              maxWidth: 350,
              maxHeight: 300,
            }}
          />
          <div style={{color: helperTextColor}}>{values.comment.length} / 350</div>
        </div>
        <div className="inspection-item-detail-image">
          <InspectionDeviationImage 
            inspectionDetailValues={values} 
            webcamIsOpen={webcamIsOpen} 
            setWebcamIsOpen={setWebcamIsOpen} 
          />
        </div>
        <div className="inspection-item-button-area">
          <CancelButton 
              handleCancel={handleResetInspectionItem} 
              title="Hætta við frávik" />
          <ConfirmationButton 
              handleSubmit={ handleSubmit } 
              title="Staðfesta frávik" />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionItemModal;
