import React from "react";
import "./CompanyDetailsContainer.css";
import { useLocation, useNavigate } from "react-router";
import ConfirmationButton from "../../Inspection/ConfirmationButton/ConfirmationButton";
import CompanyDetailsCard from "../CompanyDetailsCard/CompanyDetailsCard";
import useGetFarmById from "../../../hooks/useGetFarmById";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import CancelButton from "../../Inspection/CancelButton/CancelButton";

const CompanyDetailsContainer = () => {
  const location = useLocation();
  const selectedFarm = location.state.farm;
  const identity = selectedFarm.identity.split("-");
  if(identity[0] === "Bú") {
    selectedFarm.identity = "Bu-" + identity[1]
  }
  const { farm, isLoading } = useGetFarmById(selectedFarm.identity);
  farm.inspectionTypeInInspection = [];
  let navigate = useNavigate(); 
  const handleStart = () => {
    let path = `/preparation`; 
    navigate(path, {state:{farm: farm}});  
  }
  const handleBack = () => {
    navigate('/company');
  }
  return (
    <div className="company-details-container">
      { !isLoading ?
      <>
        <div className='company-details-container-header'>
          <div>{farm.companyName}</div>
          <b>{farm.name}</b>
          <div>{farm.identity}</div>
          <div>{farm.companySSN}</div>
        </div>
        <div>
          {
            farm.farmProductionTypes.map((fpt, i) => 
              <CompanyDetailsCard key={i} fpt={fpt} />
            )
          }
        </div>
        <div className="company-details-button">
          <CancelButton title="Til baka" handleCancel={handleBack} />
          <ConfirmationButton title="Hefja eftirlit" handleSubmit={handleStart} />
        </div>
      </>
      : <LoadingScreen />
      }
    </div>
  );
};

export default CompanyDetailsContainer;