import React, { useState } from "react";
import "./CompanyView.css";
import SearchBar from "../../SearchBar/SearchBar";
import useSearchBar from "../../../hooks/useSearchBar";
import CompanyItemList from "../CompanyItemList/CompanyItemList";
import { useNavigate } from "react-router";
import FilterListIcon from '@material-ui/icons/FilterList';
import CompanyFilter from "../CompanyFilter/CompanyFilter";

const CompanyView = ({ filteredInspectionSchedule, inspectionTypes, districts, handleInspectionTypeFilterCheck, handleDistrictFilterCheck }) => {
  const [companyFilterIsOpen, setCompanyFilterIsOpen] = useState(false);
  const { searchResult, handleSearchBarChange, searchTerm } = useSearchBar(
    filteredInspectionSchedule,
    "searchKey"
  );
  let navigate = useNavigate(); 
  const handleSelectFarm = (farm) => {
    //let path = `/company/${farm.identity}`; 
    let path = `/preparation`; 
    navigate(path, {state:{farm: farm}});
  }
  const handleOpenCompanyFilter = () => {
    setCompanyFilterIsOpen(true);
  }
  const handleCloseCompanyFilter = () => {
    setCompanyFilterIsOpen(false);
  }
  const itemsToShow = searchResult.slice(0,10);
  return (
    <div className="company-view">
      <div className="company-loaded" aria-label="simple table">
        <div className="company-loaded-upper">
          <SearchBar
            searchTerm={searchTerm}
            handleSearchBarChange={handleSearchBarChange}
            placeHolder="Leita að eftirlitsþega"
          />
          <FilterListIcon fontSize="large" className="company-loaded-filter-icon" onClick={handleOpenCompanyFilter}/>
        </div>
        <CompanyItemList
          companies={itemsToShow}
          handleSelectFarm={handleSelectFarm}
        />
      </div>
      <CompanyFilter
       open={companyFilterIsOpen} 
       handleClose={handleCloseCompanyFilter} 
       inspectionTypes={inspectionTypes}
       districts={districts}
       handleInspectionTypeFilterCheck={handleInspectionTypeFilterCheck} 
       handleDistrictFilterCheck={handleDistrictFilterCheck}
       />
    </div>
  );
};

export default CompanyView;