
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useRefreshToken from "../../hooks/useRefreshToken";

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();

    if(user) {
      if(isTokenExpired) {
        refreshToken(user);
        setIsTokenExpired(user.tokenValidTo < new Date())
      }
    }
    if(!user.tokenValidTo) {
      return <Navigate to="/test" />
    }
    return children;
};
export default PrivateRoute;
