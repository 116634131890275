import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import "./CompanyItemListItem.css";
import { formatDate } from "../../helpers/formatDateHelper";

const CompanyItemListItem = ({ company, handleSelectFarm }) => {
  const dateToShow = formatDate(company.nextInspectionDate);
  return (
    <TableRow className="company-item" onClick={() => handleSelectFarm(company)}>
      <TableCell>{company.name}</TableCell>
      <TableCell>{company.identity}</TableCell>
      <TableCell>{company.companyName}</TableCell>
      <TableCell>{company.districtIsName}</TableCell>
      <TableCell>{company.inspectionTypeIsName}</TableCell>
      <TableCell>{dateToShow}</TableCell>
      <TableCell>{company.reasonName}</TableCell>
    </TableRow>
  );
};

export default CompanyItemListItem;