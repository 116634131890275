import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import "./CompanyItemList.css";
import CompanyItemListItem from "../CompanyItemListItem/CompanyItemListItem";

const CompanyItemList = ({ companies, handleSelectFarm }) => {
  return (
      <Table className="company-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell><b>Bú</b></TableCell>
            <TableCell><b>Búsnúmer</b></TableCell>
            <TableCell><b>Umráðamaður</b></TableCell>
            <TableCell><b>Umdæmi</b></TableCell>
            <TableCell><b>Eftirlit</b></TableCell>
            <TableCell><b>Næsta eftirlit</b></TableCell>
            <TableCell><b>Ástæða</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.length > 0 ? (
            companies.map((company, i) => (
              <CompanyItemListItem
                company={company}
                key={i}
                handleSelectFarm={handleSelectFarm}
              />
            ))
          ) : (
            <TableRow>
              <TableCell>
                Ekkert fannst
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
  );
};

export default CompanyItemList;